import React, { useEffect, useState } from 'react';
import OldHome from './old_home';
import NewHome from './new_home';
import { isAppV2, isOldApp } from '../../services/versionManager';

const Home: React.FC = () => {
  const [newHome, setNewHome] = useState(null);

  useEffect(() => {
    setNewHome(isAppV2() || !isOldApp());
  }, []);

  return (newHome !== null ? (newHome === true ? <NewHome/> : <OldHome/>) : <></>);
};

export default Home;
